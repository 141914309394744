.table-class{
    padding-right: 20%;
    padding-left:20%;
}

.img-text {
    margin-left: 30%;
}

.col-asso{
    padding-right: 20%;
    padding-left:20%;
}