/* Set a background image by replacing the URL below */
.fondo {
  background: url('../../assets/images/portada.png') no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  -o-background-size: cover;
  overflow: auto;
  height: 100px;

}

body {
  height: 100px;
  overflow: auto;
}

#dimScreen {
  width: 100%;
  height: 100%;
  background: url('../../assets/images/portada.png') no-repeat center center fixed;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  /* Just to keep it at the very top */
}

.text-home {
  color: white;
  font-size: 200%;
  margin-top: 10%;
  margin-right: 20%;
  margin-left: 20%;
  text-shadow: 0 0 3px #0e233d, 0 0 5px #0000FF;
}

.imageHome {
  margin-right: 20%;
  margin-left: 20%;
  width: 60%;
}